import Head from 'next/head';
import { FC } from 'react';
import { Container } from 'react-bootstrap';

import cx from 'classnames';
import renderCommonMetaTags from 'utils/renderCommonMetaTags';

import MenuStructure from 'components/MenuStructure/MenuStructure';
import { ICommonMainLayoutPropsForPages } from 'models/ICommonMainLayoutPropsForPages';
import { IMenu } from 'models/IMenu';

import MainLayout from '../../layouts/MainLayout';

import s from './Page404Content.module.scss';

const PAGE_404_MENU_STRUCTURE: IMenu[] = [
  {
    label: 'Home',
    href: '/',
    cyID: 'home',
  },
  {
    label: 'Champions Club',
    href: '/subscribe',
    cyID: 'subscribe',
  },
  {
    label: 'Contact Us',
    href: '/contact-us',
    cyID: 'contact-us',
  },
  {
    label: 'FAQs',
    href: '/faqs',
    cyID: 'faqs',
  },
];

interface IProps {
  layoutProps?: ICommonMainLayoutPropsForPages;
}
const Page404Content: FC<IProps> = ({ layoutProps = {} }) => {
  return (
    <>
      <Head>
        {renderCommonMetaTags(
          'Surf Life Saving Foundation - Page Not Found',
          'Page not Found',
          `/imgs/404background.jpg`,
          undefined,
        )}
      </Head>

      <MainLayout {...layoutProps}>
        <div
          className={cx(
            'd-flex py-5 justify-content-center align-items-center',
            s.background,
          )}
        >
          <Container
            className={cx(
              'position-relative z-100 d-flex flex-column align-items-center',
              s.container,
            )}
          >
            <h1 className={cx('text-white d-flex text-center mb-4', s.h1)}>
              4
              <img
                loading="lazy"
                src="/imgs/logo.svg"
                alt="Surf Life Saving Foundation"
                className={cx('mx-2 mx-md-3', s.logo)}
              />
              4
            </h1>
            <h2 className={cx('text-white text-center mb-4', s.h2)}>
              Unfortunately the page you were looking for has had its flags
              taken in.
            </h2>
            <p className={cx('text-white text-center mb-4', s.p)}>
              Try going back to the previous page. Alternatively, use the links
              to find the information you are looking for.
            </p>

            <MenuStructure
              menuItemProps={{
                className: 'text-white text-decoration-underline px-3',
              }}
              className="d-flex flex-wrap justify-content-center p-0 mb-5 text-white list-style-type-none"
              menu={PAGE_404_MENU_STRUCTURE}
            />
          </Container>
        </div>
      </MainLayout>
    </>
  );
};

export default Page404Content;
